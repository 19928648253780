.director-section {
    background-image: url('../public/image-10@2x copy.png');
    /* Path to your background image */
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    /* Add padding for spacing */
    position: relative;
}

.director-heading {
    font-weight: bold;
}

.para {
    font-size: 12px;
    line-height: 30px;
}

.red {
    color: red;
    font-weight: bold;
}

.director-image-column {
    display: flex;
    justify-content: end;
    align-items: end;
}

.director-image {
    max-width: 100%;
    /* Adjust as needed */
    border-radius: 10px;
}

.director-info-column {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    /* Assuming white text on a dark background */
    text-align: left;
    padding: 20px;
}

.director-info h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.director-info p {
    font-size: 1rem;
    margin-bottom: 10px;
}

.bottom-path {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #3A3A3A;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 10px;
    /* Add some padding for smaller screens */
    flex-direction: row;
}

.namesctions {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 30px;
    color: white;
    margin-left: 60px;

}

.bottom-path .logo {
    max-height: 70px;
    width: auto;
    margin-left: 300px;
}

.nameclass {
    color: white;
    margin-left: 30px;
    text-align: left;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .bottom-path {
        flex-direction: column;
        height: auto;
        padding: 20px;
    }

    .bottom-path .logo {
        max-height: 50px;
        margin-left: 0;
    }

    .nameclass {
        margin-left: 0;
        margin-top: 10px;
        text-align: center;
    }
}

@media (max-width: 576px) {
    .bottom-path {
        padding: 15px;
    }

    .bottom-path .logo {
        max-height: 40px;
    }

    .nameclass {
        font-size: 14px;
    }

    .nameclass strong {
        font-size: 16px;
    }
}