.form-div {
    width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background: white;
}

.contact-main {
    padding: 20px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEF4F4;
}