.landing-main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    color: white;
    text-align: center;
}

.image-container h1 {
    font-weight: bold;
}

.full-img {
    width: 100%;
    /* Ensure it takes up full container width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Ensure the image covers the container */
    border-radius: 1000px;
    /* Keep the circular shape */
    max-width: 300px;
    /* Limit maximum width for large screens */
    max-height: 300px;
    /* Limit maximum height for large screens */
}

.logo-img img {
    height: 140px;
    width: 300px;
    padding: 10px;
}

.feautrescar {
    display: flex;
    /* Make it a flex container */
    align-items: start;
    /* Align items vertically centered */
    padding: 20px;
    color: white;
    background: rgb(37, 37, 37);
    font-size: 20px;
    font-weight: bold;
    border-radius: 20px;
    margin-bottom: 20px;
    /* Add some space between cards */
    text-align: left;


}

.custom-container {
    max-width: 1200px;
    /* Limit the container width */
    margin: 0 auto;
    padding-top: 100px;
    /* Center the container */
}

.mt-5 {
    margin-top: 3rem;
}


.column {
    width: 200px;
    height: 74px;
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.timer span {
    font-size: 30px;
    padding: 10px;
    margin-top: 20px;
    color: red;
}

.career-container {
    color: black;
}

.career-box {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    /* Light background for the box */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
    border: 1px solid #ddd;
    /* Optional border */
    padding: 15px;
}

.career-box input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.career-box label {
    font-size: 16px;
    color: #333;
}

.ml-2 {
    margin-left: 10px;
    /* Adjust margin between checkbox and text */
}

.landing-main {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.boldHeading {
    font-weight: bold;
}

.accordion-button {
    background-color: transparent;
    /* Background when inactive */
    color: black;
    /* Default text color */
}

.accordion-button:not(.collapsed) {
    background-color: red;
    /* Active background color */
    color: black;
    /* Active text color */
}

.accordion-body {
    color: black;
    /* Body text color */
}

/* F8F9FA */
.bg-second {
    background: #F8F9FA;
}

.data {
    color: #218838;
}

.bg-red {
    background-color: black;
    /* Active Header Color */
}

.bg-green {
    background-color: gray;
    color: white;
    /* Body Background Color */
}

/* Optional: Style the accordion header for better visibility */
.accordion-button:not(.collapsed) {
    background-color: red;
    /* Change this if you want to adjust the active header */
    color: white;
    /* Change text color if needed */
}

.accordion-body {
    padding: 15px;
    /* Adjust padding for body */
}

@media (max-width: 768px) {
    .full-img {
        max-width: 200px;
        /* Adjust size for tablets or small screens */
        max-height: 200px;
    }
    .feautrescar{
        text-align: center;
        align-items: center;
    }
}

@media (max-width: 576px) {
    .full-img {
        max-width: 150px;
        /* Adjust size for mobile devices */
        max-height: 150px;
    }
}