.reviews-container {
    padding: 20px;
}

.review-card {
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 254px !important;
}

.review-card:hover {
    transform: translateY(-5px);
}

.review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.review-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
}

.review-name {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.review-description {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

.review-rating .star-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media (max-width: 991.98px) {
    .review-name {
        font-size: 16px;
    }

    .review-description {
        font-size: 14px;
    }

    .review-rating .star-icon {
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 767.98px) {
    .review-header {
        margin-bottom: 8px;
    }

    .review-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .review-name {
        font-size: 14px;
    }

    .review-description {
        font-size: 13px;
    }

    .review-rating .star-icon {
        width: 16px;
        height: 16px;
    }
}