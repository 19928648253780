/* Existing Styles */
/* ... */

.payment-page {
    font-family: "Poppins", sans-serif !important;
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.container {
    margin: 0 auto;
}

.header {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.logo {
    width: 120px;
    margin-right: 20px;
}

.main-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 80%;
}

.course-details {
    width: 100%;
}

.course-details h2 {
    color: red;
}

.course-details ol {
    padding-left: 20px;
}

.icons i {
    font-size: 24px;
    margin-right: 15px;
}

.payment-form {
    /* background-color: white; */
    padding: 20px;
    width: 90%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */

}

.payment-form-absolute {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    z-index: 10;
}

.payment-form h2 {
    color: red;
}

.payment-form form {
    display: flex;
    flex-direction: column;
}

.payment-form label {
    margin-bottom: 5px;
    font-weight: bold;
}

.payment-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.payment-form button {
    background-color: red;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.payment-form button:hover {
    background-color: darkred;
}

footer {
    margin-top: 40px;
    padding: 20px 0;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
}

.contact-info p,
.terms p {
    margin: 5px 0;
}

.contact-info i {
    margin-right: 10px;
}

.what_you_get {
    display: flex;
    justify-content: start;
    /* flex-direction: column; */
    align-items: start;
    background: linear-gradient(90deg, #ED2326 0%, #1F1F1F 100%);
    color: white;
    padding: 20px;
}

.what_you_get h3 {
    font-weight: bold;
}

.social {
    display: flex;
    align-items: center;
}

.icon-container {
    display: flex;
    /* Flexbox to display icons in a row */
    justify-content: flex-start !important;
    /* Align icons to the start */
    padding: 10px 0;
    /* Adjust padding as needed */
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
    justify-content: center;
    /* Center the content */
    align-items: center;
    /* Vertically center */
    text-align: center;
    /* Center text */
}

.contact-info span {
    margin: 0 5px;
    /* Add some margin between elements */
}

@media (max-width: 480px) {
    .contact-info {
        flex-direction: column;
        /* Stack items vertically on small screens */
        align-items: flex-start;
        /* Align items to the start */
    }

    .contact-info span {
        margin: 2px 0;
        /* Adjust margin for vertical stacking */
    }
}


/* For tablets and smaller screens (up to 768px) */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .course-details, .payment-form, .payment-details {
        width: 100%;
        margin-bottom: 20px;
    }

    .payment-form {
        padding: 20px;
        width: 95%;
    }

    .payment-form h2, .course-details h2 {
        font-size: 1.5rem;
    }

    .payment-form button {
        font-size: 14px;
        padding: 12px;
    }

    footer {
        flex-direction: column;
        text-align: center;
    }

    .contact-info {
        margin-bottom: 10px;
    }

    .what_you_get ul {
        font-size: 12px;
        list-style-type: disc;
        padding-left: 20px;
        margin: 0;
    }

    .what_you_get {

        margin-top: 220px;
    }
}

/* For mobile screens (up to 480px) */
@media (max-width: 480px) {
    .header {
        justify-content: center;
        padding: 10px 0;
    }

    .logo {
        width: 100px;
    }

    .main-content {
        width: 100%;
        flex-direction: column;
    }

    .course-details h2 {
        font-size: 1.2rem;
    }

    .course-details ol {
        font-size: 14px;
        padding-left: 15px;
    }

    .icons i {
        font-size: 20px;
        margin-right: 10px;
    }

    .payment-form {
        padding: 15px;
        width: 100%;
        box-shadow: none;
    }

    .payment-form h2 {
        font-size: 1.4rem;
    }

    .payment-form button {
        padding: 10px;
        font-size: 12px;
    }

    input, label {
        font-size: 14px;
    }

    footer {
        flex-direction: column;
        text-align: center;
        padding: 10px;
    }

    .contact-info i {
        font-size: 16px;
    }

    .contact-info p {
        font-size: 14px;
    }
}