/* nav bar css */
.logo-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding: 10px;
}

.connecting-careers-final-logo- {
    width: 193px;
    height: 90px;
    object-fit: cover;
}

.iconfeather-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.nav-links .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 15px;
}

.nav-links .nav-link:hover {
    color: red;
    text-decoration: underline;
}

.menu-toggle {
    background: none;
    border: none;
    cursor: pointer;
}

.nav-col {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .connecting-careers-final-logo- {
        width: 150px;
        height: 70px;
    }

    .iconfeather-icon {
        display: none;
    }

    .nav-links {
        display: none !important;
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }

    .nav-col.open .nav-links {
        display: flex !important;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: 10px 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}