.whatsapp-icon {
  position: fixed;
  /* Position the icon relative to the viewport */
  bottom: 20px;
  /* Distance from the bottom */
  right: 20px;
  /* Distance from the right */
  z-index: 1000;
  /* Ensures the icon is on top of other elements */
}

.whatsapp-icon img {
  height: 50px;
  width: 50px;
  border-radius: 50px;

}
@media only screen and (max-width: 600px) {
  .whatsapp-icon {
    bottom: 10px; /* Adjust the position for small screens */
    right: 10px;
  }
}
