.section-heading {
    width: 100%;
    height: auto;
    padding: 20px;

}
.section-text{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
    flex-direction: column;
}